import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import _ from "lodash";

export type CustomerTableRow = {
    id: number;
    address: {
        comment: string;
    }
}

export type CustomerInfo = {
    id: number,
    email?: string,
    login: string,
    paymentType: any[],
    isCashless?: boolean,
    address?: any[],
    auditDateTime?: string|null,
    taskAuditListSchedule: {
        daysOfMonth: number[],
        daysOfWeek: number[],
        hour: number,
        minute: number,
    }
}
export const customerApi = createApi({
    reducerPath: 'customerApi',
    baseQuery: fetchBaseQuery({baseUrl: process.env.NEXT_PUBLIC_API_HOST_BASE_URL}),
    endpoints: ({query, mutation}) => ({
        getCustomersShort: query({
            query: (p) => ({
                url: `/api/customers-autocomplete`,
                params: p
            }),
            transformResponse(response:any[], meta, arg) {
                const r: any[] = [];

                response.forEach((v) => {
                    if (v.reception && v.reception !== "") {
                        r.push({
                            id: v.id,
                            text: v.reception
                        });
                    }
                })
                return r
            }
        }),
        getCustomerTableRows: query<CustomerTableRow[], void>({
            query: () => '/api/customers-short'
        }),
        getCustomerEditForm: query<CustomerInfo, number>({
            query: (id) => `/api/customers/${id}/edit-form`
        }),
        updateCustomerAuditDatetime: mutation<any, Partial<CustomerInfo> & Pick<CustomerInfo, 'id'>>({
            query: ({id, ...patch}) => {
                console.log(id)
                console.log(patch)
                return ({
                    url: `/api/customers/${id}`,
                    headers: {'Content-Type': 'application/merge-patch+json'},
                    method: 'PATCH',
                    body: JSON.stringify(patch)
                })
            }
        }),
        updateCustomerSchedule: mutation<any, Partial<CustomerInfo['taskAuditListSchedule']> & Pick<CustomerInfo, 'id'>>({
            query: ({id, ...patch}) => {
                console.log(id)
                console.log(patch)
                return ({
                    url: `/api/customers/${id}/schedule`,
                    headers: {'Content-Type': 'application/merge-patch+json'},
                    method: 'PATCH',
                    body: JSON.stringify(patch)
                })
            }
        })
    }),
})
export const {
    useGetCustomersShortQuery,
    useGetCustomerTableRowsQuery,
    useGetCustomerEditFormQuery,
    useUpdateCustomerAuditDatetimeMutation,
    useUpdateCustomerScheduleMutation
} = customerApi;