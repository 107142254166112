import _ from "lodash";
import {BaseQueryFn, createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

interface RawDataItem {
    year: string;
    month: string;
    monthStart: string;
    auditQuantity: number;
}

export interface NodeYear {
    id: string,
    year: string;
    children?: NodeMonth[];
}

export interface NodeMonth {
    id: string,
    year: string;
    month: string;
    monthStart: string;
    date: string;
    auditQuantity: number;
}


export interface AuditItemCreate {
    customerId: number,
    startDate: string,
    endDate: string,
}

export type AuditItem = {
    id: number;
    receivedAt: string | null
}

export type AuditItemTableRow = {
    id: string,
    rowType: string,
    customerId: number,
    auditListId: number,
    name: string,
    auditItemId: number,
    sferaNo: string,
    customerNo: string,
    totalPrice: number,
    receivedAt: string,
    topPartnerTotalPrice: number,
    subRows?: AuditItemTableRow[],
    isPaid: boolean;
    // isBilled: boolean;
    // isDelivered: boolean;
    status: string;
    startDate: string;
    endDate: string;
    partnerComment?: string;
}
export type TestType = {
    id: number,
    name: string
}
// Define a service using a base URL and expected endpoints
export const auditApi = createApi({
    reducerPath: 'auditApi',
    tagTypes: ['AuditDateTree', 'AuditListTable', 'AuditItem', 'Test'],
    baseQuery: fetchBaseQuery({baseUrl: process.env.NEXT_PUBLIC_API_HOST_BASE_URL}),
    endpoints: ({query, mutation}) => ({
        getAuditDateTree: query<NodeYear[], void>({
            query: () => '/api/audit/tree',
            transformResponse: (baseQueryReturnValue: BaseQueryFn<RawDataItem[]>, meta, arg): NodeYear[] => {
                const groupedByYear = _.groupBy(baseQueryReturnValue, 'year');
                const result = _.map(groupedByYear, (items, year) => {
                    const sortedItems: NodeMonth[] = _.orderBy(_.map(items, (v: any): NodeMonth => {
                        return {
                            id: `y_${year}_m_${v.month}`,
                            year: v.year,
                            month: v.month,
                            monthStart: v.monthStart,
                            date: v.monthStart,
                            auditQuantity: v.auditQuantity
                        }
                    }), ['monthStart'], ['asc']);
                    return {
                        id: `y_${year}`,
                        year: year,
                        children: _.orderBy(sortedItems, ['month'], ['desc'])
                    };
                });
                const sortedResult = _.orderBy(result, ['year'], ['desc']);
                console.log('getAuditDateTree', sortedResult);
                return sortedResult;
            },
            providesTags: ['AuditDateTree'],
        }),
        getAuditListMonth: query({
            query: (p) => ({
                url: `/api/audit_list_month`,
                params: p //?startDate=2024-03-01
            }),
        }),
        createAuditItem: mutation<void, AuditItemCreate>({
            query: (auditItemCreate) => {
                // console.log('auditItemCreate', auditItemCreate)
                // return;
                return {
                    url: `/api/customers/${auditItemCreate.customerId}/make-audit-list`, // Здесь предполагается, что `id` - это часть `AuditItemCreate`, если нет, то структуру объекта запроса нужно будет изменить
                    method: 'POST',
                    body: auditItemCreate,
                }
            },
            invalidatesTags: ['AuditDateTree'],
        }),
        // updateAuditItem: mutation<AuditItem, Partial<AuditItem> & Pick<AuditItem, 'id'>>({
        //     query: ({id, ...patch}) => {
        //         // console.log(id, patch)
        //         // return;
        //         return {
        //             url: `/api/audit_items`, // Здесь предполагается, что `id` - это часть `AuditItemCreate`, если нет, то структуру объекта запроса нужно будет изменить
        //             headers: {
        //                 'content-type': 'application/merge-patch+json',
        //             },
        //             method: 'PATCH',
        //             body: JSON.stringify({id, patch}),
        //         }
        //     },
        //     invalidatesTags: ['AuditListTable'],
        // }),
        updateAuditItem: mutation<AuditItem, Partial<AuditItem> & { ids: Number[] }>({
            query: ({ids, ...patch}) => {
                // console.log(id, patch)
                // return;
                return {
                    url: `/api/audit_items`, // Здесь предполагается, что `id` - это часть `AuditItemCreate`, если нет, то структуру объекта запроса нужно будет изменить
                    headers: {
                        'content-type': 'application/merge-patch+json',
                    },
                    method: 'PATCH',
                    body: JSON.stringify({ids, data: patch}),
                }
            },
            invalidatesTags: ['AuditListTable'],
        }),
        getAuditListTable: query<AuditItemTableRow[], number>({
            query: (id: number): string => `/api/audit_list/${id}/table/flat`,
            providesTags: ['AuditListTable'],
            // providesTags: (result:AuditItemTableRow[], error, arg) =>
            // {
            //     console.log('getAuditListTable', result, error, arg)
            //     return result
            //         ? [
            //             ...result.flatMap((item) => [
            //                 { type: 'AuditItem', id: item.id },
            //                 ...(item.subRows ? item.subRows.map((subRow) => ({ type: 'AuditItem', id: subRow.id })) : []),
            //             ]),
            //             'AuditListTable',
            //         ]
            //         : ['AuditListTable']
            // },
        }),
        updateAuditItemStatus: mutation<void, { id: number, statusCode: string }>({
            query: ({id, statusCode}) => ({
                url: `/api/audit_items/${id}/status`,
                method: 'PATCH',
                body: {statusCode}
            }),
            invalidatesTags: (result, error, arg) => {
                console.log('updateAuditItemStatus', result, error, arg)
                // return ['AuditItem']
                return [{type: 'AuditItem', id: arg.id}, 'AuditListTable']
            },
            // invalidatesTags: ['AuditListTable'],
        }),
        updateAuditItemStatuses: mutation<void, { ids: number[], statusCode: string }>({
            query: ({ids, statusCode}) => {
                return {
                    url: `/api/audit_item/statuses`, // Assuming this is a bulk update endpoint
                    method: 'PATCH',
                    headers: {
                        'content-type': 'application/merge-patch+json',
                    },
                    body: JSON.stringify({ids, statusCode}),
                };
            },
            invalidatesTags: (result, error, arg) => {
                console.log('updateAuditItemStatus', result, error, arg)
                // return ['AuditItem']
                return ['AuditListTable']
                return arg.ids.map((id) => ({type: 'AuditItem', id}));
            },
            // invalidatesTags: ['AuditListTable'],
        }),
        updateAuditItemIsPaid: mutation<void, { ids: number[], isPaid: boolean }>({
            query: ({ids, isPaid}) => {
                return {
                    url: `/api/audit_item/paid`, // Assuming this is a bulk update endpoint
                    method: 'PATCH',
                    headers: {
                        'content-type': 'application/merge-patch+json',
                    },
                    body: JSON.stringify({ids, isPaid}),
                };
            },
            invalidatesTags: (result, error, arg) => {
                console.log('updateAuditItemStatus', result, error, arg)
                // return ['AuditItem']
                return ['AuditListTable']
                // return arg.ids.map((id) => ({type: 'AuditItem', id}));
            },
            // invalidatesTags: ['AuditListTable'],
        }),
        // getTestItems: query<TestType[], void>({
        //     query: () => '/table-test',
        //     providesTags: (result: TestType[]) =>
        //         result
        //             ? result.map((item) => ({type: 'Test', id: item.id}))
        //             : [],
        //     // providesTags: (result:TestType[]) =>
        //     //     result ? [...result.map(
        //     //         (item) => ({type: 'Test', id: item.id})
        //     //     ), 'Test'] : ['Test'],
        //     // providesTags: ['Test'],
        // }),
        // // Запрос для отдельной строки
        // getTestItemById: query<{ id: number, name: string }, number>({ // указать тип аргумента запроса (id)
        //     query: (id) => `/table-test/${id}`, // Подразумевается, что `/table-test/:id` возвращает данные для конкретной строки
        //     providesTags: (result, error, id) => [{type: 'Test', id}],
        // }),
        // updateTestItem: mutation({
        //     query: ({id}) => ({
        //         url: `/table-test/${id}`,
        //         method: 'POST',
        //         body: {id},
        //     }),
        //     async onQueryStarted({id}, {queryFulfilled, dispatch}) {
        //         const {data: newData} = await queryFulfilled;
        //         console.log('updateTestItem', newData)
        //         dispatch(
        //             auditApi.util.updateQueryData('getTestItems', undefined, (draft) => {
        //                 const q = draft.find((i) => i.id === newData?.id)
        //                 Object.assign(q, {id, name: 'qwe'})
        //             })
        //         )
        //     },
        //     // invalidatesTags: (result, error, { id }) => [{ type: 'Test', id }], // Инвалидация только по конкретному ID
        // }),
    }),
})

export const {
    useGetAuditDateTreeQuery,
    useCreateAuditItemMutation,
    useGetAuditListMonthQuery,
    useGetAuditListTableQuery,
    useUpdateAuditItemMutation,
    useUpdateAuditItemStatusesMutation,
    useUpdateAuditItemIsPaidMutation,
} = auditApi;