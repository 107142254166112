import {configureStore} from '@reduxjs/toolkit'
import {auditApi} from "@/app/dashboard/audit/services/audit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {customerApi} from "@/app/dashboard/customers/services/customer";
import {ordersApi} from "@/app/dashboard/orders/services/orders";
export const store = configureStore({
    reducer: {
        [auditApi.reducerPath]: auditApi.reducer,
        [customerApi.reducerPath]: customerApi.reducer,
        [ordersApi.reducerPath]: ordersApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(auditApi.middleware)
            .concat(customerApi.middleware)
            .concat(ordersApi.middleware)
    ,
})

setupListeners(store.dispatch)
// Infer the type of makeStore
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;