'use client';

import {BaseStyles, ThemeProvider} from "@primer/react";
import React from "react";
import {Provider} from "react-redux";
import {store} from "@/app/lib/store";

export function Providers({children}: Readonly<{ children: React.ReactNode }>) {
    return (
        <Provider store={store}>
            <ThemeProvider>
                <BaseStyles>
                    {children}
                </BaseStyles>
            </ThemeProvider>
        </Provider>
    );
}