import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ColumnFilter} from "@tanstack/react-table";
import * as querystring from "querystring";

export type OrderRow = {
    id: number;
    name: string | null;
    sferaNo: number;
    customerNo: string | null;
    customer: any | null;
    children: Array<any>;
    status: { id: number; name: string; } | null;
    dispatchAt: Date | null;
    // orderRef: {
    //     customer: {
    //         address: {
    //             comment: string | null;
    //         }
    //     }
    //
    // },
    price: number;
    totalPrice: number;
    topPartnerPrice: number;
    topPartnerTotalPrice: number;
}

export type PagingListResponse<T> = {
    items: T[];
    total: number;
    lastPage: number;
    perPage: number;
}

export type filterQuery = {
    id: string;
    value: string;
};
// export type OrderItemPaging = {
//     total: number
// }
export const ordersApi = createApi({
    reducerPath: 'ordersApi',
    baseQuery: fetchBaseQuery({baseUrl: process.env.NEXT_PUBLIC_API_HOST_BASE_URL}),
    tagTypes: ['Orders'],
    endpoints: (builder) => ({
        listOrders: builder.query<PagingListResponse<OrderRow>, {
            pageIndex: number,
            pageSize: number,
            columnFilters: ColumnFilter[]
        }>({
            query: ({pageIndex = 1, pageSize = 30, columnFilters}) => {
                const query = querystring.stringify(columnFilters.reduce((acc, item) => ({
                    ...acc,
                    [item.id]: item.value
                }), {}));
                console.log(query)
                return `/api/dashboard/order-item/list?page=${pageIndex < 1 ? 1 : pageIndex}&itemsPerPage=${pageSize}&${query}`
            },
            providesTags: (result, error, page) =>
                result
                    ? [
                        // Provides a tag for each post in the current page,
                        // as well as the 'PARTIAL-LIST' tag.
                        ...result.items.map(({id}) => ({type: 'Orders' as const, id})),
                        {type: 'Orders', id: 'PARTIAL-LIST'},
                    ]
                    : [{type: 'Orders', id: 'PARTIAL-LIST'}],
        }),
    }),
})
export const {
    // useGetTotalQuery,
    useListOrdersQuery
} = ordersApi;